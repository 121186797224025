import { Link } from "gatsby-plugin-modal-routing";
import React, { useContext, useRef } from "react";
import WorkContext from "../utils/WorkContext";

const ProjectCard = ({ project, asModal = true, prevPath = false }) => {
  const cardVideoEl = useRef(null);
  const themeContext = useContext(WorkContext);

  const handleVideoPlayback = (event) => {
    if (event.type === "mouseover" || event.type === "focus") {
      cardVideoEl.current.play();
    } else {
      cardVideoEl.current.pause();
    }
  };

  return (
    <div key={project.id} className={`showcase__item`}>
      <figure
        className="card"
      >
        <Link
          to={`/work/${project.slug}`}
          className="card__image"
          asModal={asModal}
          state={{
            modal: true,
            noScroll: true,
            prevPath: prevPath,
          }}
          onMouseOver={handleVideoPlayback}
          onFocus={handleVideoPlayback}
          onMouseOut={handleVideoPlayback}
          onBlur={handleVideoPlayback}
        >
          {
            // For some weird reason, swapping video source doesn't re-render the video.
            // We have to re-render the video element itself.
          }
          {themeContext.theme === "dark" && (
            <video
              className="card__video js-video"
              poster={project.coverImage.fixed.src}
              muted
              loop
              playsInline
              ref={cardVideoEl}
            >
            <source
                src={project.videoPreviewUrl || ""}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )}
          {themeContext.theme === "light" && (
            <video
              className="card__video js-video"
              poster={project.coverImage.fixed.src}
              muted
              loop
              playsInline
              ref={cardVideoEl}
            >
            <source
                src={project.alternativeVideoPreviewUrl || project.videoPreviewUrl || ""}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )}
        </Link>

        <figcaption className="card__caption">
          <h6 className="card__title">
            <Link
              to={`/work/${project.slug}`}
              asModal={asModal}
              state={{
                modal: true,
                noScroll: true,
              }}
            >
              {project.title}
            </Link>
          </h6>
          <div className="card__description">
            {project.subHeading && (
              <p className="card__subtitle">{project.subHeading}</p>
            )}
            {project.byline && project.byline !== " " ? (
              <div dangerouslySetInnerHTML={{ __html: project.byline }} />
            ) : (
              <ul>
                <li>
                  {project.runTime
                    ? `${project.runTime.replace(":", "'")}''`
                    : ""}
                </li>
                <li>{project.client}</li>
                <li>{project.category}</li>
              </ul>
            )}
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default ProjectCard;
