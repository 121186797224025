import { graphql } from 'gatsby';
import React, { useState } from 'react';
import DirectorInfo from '../components/DirectorInfo';
import ProjectCard from '../components/ProjectCard';
import TemplateWrapper from '../components/TemplateWrapper';

export default ({ data, location, transitionStatus }) => {
  const {pathname} = location;
  const directorsWork = data.allDatoCmsWork.edges;
  const {seoMetaTags} = data.datoCmsDirector;
  const [activeTab, setActiveTab] = useState('work');

  return (
    <TemplateWrapper page="director" customSeo={seoMetaTags} className={`director-tab-${activeTab}`}>
      <div className={`showcase showcase--director ${activeTab} ${transitionStatus}`}>
        {directorsWork.length ? directorsWork.map(({ node: work }) => (
          <ProjectCard project={work} key={work.slug} prevPath={pathname} asModal={false} />
        )) : <p>No work found for this director.</p>}
      </div>
      
      <DirectorInfo director={data.datoCmsDirector} />
    </TemplateWrapper>
  )
}

export const query = graphql`
  query DirectorsWorkQuery($slug: String!) {
    datoCmsDirector(slug: {eq: $slug}) {
      id
      name
      intro
      instagram
      vimeo
      avatar {
        alt
        fluid(
          maxWidth: 450
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          src
          srcSet
          sizes
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsWork(filter: {director: {slug: {eq: $slug}}}, sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          subHeading
          byline
          featured
          slug
          aspectRatio
          runTime
          year
          category
          client
          location
          videoPreviewUrl
          alternativeVideoPreviewUrl
          coverImage {
            fixed(width: 320) {
              aspectRatio
              sizes
              src
              width
              height
              srcSet
            }
          }
        }
      }
    }
  }
`;